export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const currencyFormatterInteger = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const numberFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
});

export const calculatePayoutEstimate = (value) => {
  if (value > 100000.01)
    return (100 + 1575 + 26700 + (value - 100000) * 0.6) * (1 - 0.75 * 0.3);
  if (value > 10000)
    return (100 + 1575 + (value - 10000) * 0.3) * (1 - 0.75 * 0.3);
  if (value > 1000) return (100 + (value - 1000) * 0.175) * (1 - 0.75 * 0.3);
  if (value > 5) return value * 0.1 * (1 - 0.75 * 0.3);
};
